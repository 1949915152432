import axios from "axios";
import { API_URL, API_VERSION, TOKEN_NAME, loader, toast } from "../utils";
import { openSignup } from "./LoginFormEmail";
import { openLogin } from "./SignupFormEmail";

export const ForgotPassword = () => {
  const submitForm = (event: any) => {
    event.preventDefault();
    loader(true);

    const url = API_URL + API_VERSION + "/auth/code/email";
    const data = {
      email: $("#emailInputFP").val(),
      type: "recover",
    };

    axios
      .post(url, data)
      .then((response) => {
        loader(false);
        toast("Code sent, check your email.");
        console.log(response);
        $(".step1").hide();
        $(".step2").show();
      })
      .catch((error) => {
        loader(false);
        console.error("Error en la solicitud:", error);
        toast(error.response.data.error.message);
      });
  };

  const submitFormStep2 = (event: any) => {
    event.preventDefault();
    if ($("#passwordInputFP").val() !== $("#passwordInputFPAux").val()) {
      return toast("Passwords do not match");
    }

    loader(true);

    const url = API_URL + API_VERSION + "/auth/recover/pass";
    const data = {
      email: $("#emailInputFP").val(),
      password: $("#passwordInputFP").val(),
      code: $("#codeInputFP").val(),
    };

    axios
      .post(url, data)
      .then((response) => {
        loader(false);
        toast("Password changed successfully.");
        openLogin();
        console.log(response);
      })
      .catch((error) => {
        loader(false);
        console.error("Error en la solicitud:", error);
        toast(error.response.data.error.message);
      });
  };

  return (
    <div className="modal modalForgotPassword closeModal">
      <div className="content step1">
        <div className="title">Forgot Password</div>
        <form onSubmit={submitForm} className="formLogin">
          <p></p>
          <label>
            &nbsp;&nbsp;&nbsp;&nbsp;Email:
            <input
              type="email"
              id="emailInputFP"
              placeholder="Enter your Email"
              required
            />
          </label>

          <p></p>

          <div className="btnSignup">
            <div
              className="btn btnCancel"
              onClick={() => {
                $(".closeModal").hide();
              }}
            >
              Cancel
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button className="btn">Recover</button>
            <br />
          </div>
        </form>

        <div className="endSection">
          <p>You do not have an account?</p>
          <button className="btn" onClick={openSignup}>
            Signup
          </button>
        </div>
      </div>

      <div className="content step2">
        <div className="title">Create a new password</div>
        <form onSubmit={submitFormStep2} className="formLogin">
          <p></p>
          <label>
            &nbsp;&nbsp;&nbsp;&nbsp;Recovery code:
            <input
              type="text"
              id="codeInputFP"
              placeholder="Enter your Recovery code"
              required
            />
          </label>

          <label>
            &nbsp;&nbsp;&nbsp;&nbsp;Create new password:
            <input
              type="password"
              id="passwordInputFP"
              placeholder="Enter your new password"
              required
            />
          </label>

          <label>
            &nbsp;&nbsp;&nbsp;&nbsp;Confirm password:
            <input
              type="password"
              id="passwordInputFPAux"
              placeholder="Confirm your new password"
              required
            />
          </label>

          <p></p>

          <div className="btnSignup">
            <div
              className="btn btnCancel"
              onClick={() => {
                $(".closeModal").hide();
              }}
            >
              Cancel
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button className="btn">Recover Now</button>
            <br />
          </div>
        </form>

        <div className="endSection">
          <p>You do not have an account?</p>
          <button className="btn" onClick={openSignup}>
            Signup
          </button>
        </div>
      </div>
    </div>
  );
};
