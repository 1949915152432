/* eslint-disable jsx-a11y/alt-text */
import $ from "jquery";
import {
  API_URL,
  API_VERSION,
  TOKEN_NAME,
  loader,
  setTokenLogin,
  toast,
} from "../utils";
import axios from "axios";

export const openSignup = () => {
  $(".modal").hide();
  $(".modalSignupEmail").css("display", "flex");
};

export const openFP = () => {
  $(".modal").hide();
  $(".modalForgotPassword").css("display", "flex");
};

export const LoginFormEmail = () => {
  const formLogin = (event: any) => {
    event.preventDefault();
    loader(true);

    const url = API_URL + API_VERSION + "/auth/login/email";
    const data = {
      email: $("#emailInputLogin").val(),
      password: $("#passwordInputLogin").val(),
    };

    axios
      .post(url, data)
      .then((response) => {
        setTokenLogin(response);
        toast("Successful login");
        setTimeout(() => {
          (window as any).location.href = "https://mint.spacewars.center/";
        }, 1000);
      })
      .catch((error) => {
        loader(false);
        console.error("Error en la solicitud:", error);
        toast(error.response.data.error.message);
      });
  };

  return (
    <div className="modal modalLoginEmail closeModal">
      <div className="content">
        <div className="title">Login</div>
        <form onSubmit={formLogin} className="formLogin">
          <p></p>
          <label>
            &nbsp;&nbsp;&nbsp;&nbsp;Email:
            <input
              type="email"
              id="emailInputLogin"
              placeholder="Enter your Email"
              required
            />
          </label>

          <label>
            &nbsp;&nbsp;&nbsp;&nbsp;Password:
            <input
              type="password"
              id="passwordInputLogin"
              placeholder="Enter your Password"
              required
            />
          </label>
          <p></p>
          <a className="txt-center w100" onClick={openFP}>
            I forgot my password
          </a>
          <p></p>

          <div className="btnSignup">
            <div
              className="btn btnCancel"
              onClick={() => {
                $(".closeModal").hide();
              }}
            >
              Cancel
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button className="btn">Login</button>
          </div>
        </form>

        <div className="endSection">
          <p>You do not have an account?</p>
          <button className="btn" onClick={openSignup}>
            Signup
          </button>
        </div>
      </div>
    </div>
  );
};
