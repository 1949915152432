/* eslint-disable jsx-a11y/alt-text */
import React from "react";

export const LoginFormWallet = () => {
  // $(function () {
  //   $("#logo").addClass("logoCenter");
  // });

  return (
    <div className="modal modalLogin">
      <div className="content">
        <div className="title">SignUp</div>
      </div>
    </div>
  );
};
