import { copyToClipboard, getToken, toast } from "../utils";

export const ModalProfile = () => {
  const token = getToken() as any;
  let refLink = "";
  if (token !== null) {
    refLink =
      "https://mint.spacewars.center/?referred=" + token.user.referralCode;
  }
  return (
    <div className="modal modalProfile closeModal">
      <div className="content">
        <div className="title">Profile</div>
        <div className="formLogin contentProfile">
          <img src={token.user.avatar} className="avatar" />
          <div className="infoProfile">
            User: &nbsp;{token.user.email ?? token.user.name}
          </div>
          <div>
            <div className="txt m0 txt-center">
              <div className="f22">
                Invite now: 🔗🚀
                <br />
                <span className="usAuto">{refLink}</span>
                <button
                  className="btn btnAuth btnLarge"
                  onClick={() => {
                    toast("Copied Link ✅");
                    copyToClipboard(
                      "Explore the universe with Space Wars NFT! 🚀 Secure your exclusive access to stellar spaceships and intergalactic art in our presale. 🌌 Don't miss out! 🪐.\n\n" +
                        refLink
                    );
                  }}
                >
                  <strong>COPY INVITATION</strong>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="endSection">
          <button
            className="btn"
            onClick={() => {
              $(".closeModal").hide();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
