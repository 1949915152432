/* eslint-disable jsx-a11y/alt-text */
import { ethers } from "ethers";
import {
  ABI,
  CONTRACT,
  CONTRACT_USDT,
  PRICES,
  TOKEN_NAME,
  copyToClipboard,
  getToken,
  loader,
  logout,
  profile,
  setContract,
  toast,
} from "../utils";
import { ModalMinted } from "./ModalMinted";
import { openModalMint } from "./SignupFormEmail";
import { useState } from "react";
import { ModalProfile } from "./ModalProfile";

/* eslint-disable jsx-a11y/alt-text */
const w = window as any;
const polygonProvider = new ethers.providers.JsonRpcProvider(
  "https://polygon-rpc.com"
);

const checkNetwork = async () => {
  const provider = new ethers.providers.Web3Provider(w.ethereum);
  const network = await provider.getNetwork();
  return network.chainId;
};

export const MintPage = () => {
  const [isMinted, setIsMinted] = useState(false);

  let nftMinted = null;
  const token = getToken() as any;
  let refLink = "";
  if (token !== null) {
    refLink =
      "https://mint.spacewars.center/?referred=" + token.user.referralCode;
  }

  const provider = new ethers.providers.Web3Provider(w.ethereum);
  const contract = new ethers.Contract(CONTRACT, ABI, provider);
  const contractUSDT = new ethers.Contract(CONTRACT_USDT, ABI, provider);

  const minted = (nft: any) => {
    nftMinted = nft;
    toast("Mint Successful");
    w.open("https://bscscan.com/token/" + nft.hash, "_blank");
    setIsMinted(true);
  };

  const getPrice = (idNft: 1 | 2 | 3, type: "PLANET" | "SPACESHIP") => {
    return PRICES[type][idNft];
  };

  const buyNft = async (idNft: 1 | 2 | 3, type: "PLANET" | "SPACESHIP") => {
    loader(true);
    try {
      const chainId = await checkNetwork();
      if (chainId !== 137) {
        const params = [
          {
            chainId: "0x89",
            chainName: "Polygon Mainnet",
            nativeCurrency: {
              name: "MATIC",
              symbol: "MATIC",
              decimals: 18,
            },
            rpcUrls: ["https://polygon-rpc.com"],
            blockExplorerUrls: ["https://polygonscan.com"],
          },
        ];
        await w.ethereum.request({
          method: "wallet_addEthereumChain",
          params,
        });
        return ((window as any).location.href =
          "https://mint.spacewars.center/");
      }
    } catch (e: any) {
      console.log("e", e);
      loader(false);
      return;
    }

    const buyFn = type === "PLANET" ? "buyPlanet" : "buySpaceship";
    try {
      const signer = provider.getSigner();
      const contratoConFirma = contract.connect(signer);
      const contratoConFirmaUSDT = contractUSDT.connect(signer);

      const allowance = await contratoConFirmaUSDT.allowance(
        signer.getAddress(),
        CONTRACT
      );
      const priceNft = getPrice(idNft, type);

      const buyNow = async () => {
        loader(true);
        try {
          const tx = await contratoConFirma[buyFn](idNft, 1);
          toast("Loading...");
          await tx.wait();
          minted(tx);
          loader(false);
        } catch (error: any) {
          console.log("error", error);
          loader(false);
          try {
            toast(error.reason.replace("execution reverted: ", ""));
          } catch (error) {
            console.log(error);
          }
        }
      };

      if (allowance >= priceNft) {
        buyNow();
      } else {
        try {
          const approve = await contratoConFirmaUSDT.approve(
            CONTRACT,
            priceNft
          );
          toast("Loading...");
          await approve.wait();
          if (approve) {
            buyNow();
          } else {
            toast("Not Approve");
            loader(false);
          }
        } catch (error: any) {
          console.log("error", error);
          toast(error.reason);
          loader(false);
        }
      }
    } catch (e: any) {
      console.log("e 138", e);

      loader(false);
    }
  };

  return (
    <div>
      <div>
        <img src="assets/logo.png" id="logo" />
        <button className="btn profile" onClick={profile}>
          <strong>PROFILE</strong>
        </button>

        <button className="btn logout" onClick={logout}>
          <strong>LOGOUT</strong>
        </button>

        <div className="sec-title">
          <h1 className="txt-center">Space Wars Presale Launch</h1>
          <span className="bottom-curve"></span>
        </div>
        <div className="boxed-txt">
          Unlock the cosmos with Space Wars presale. Connect your wallet and be
          part of the celestial journey.
        </div>
        <div className="miniGalaxy">
          <br />
          <br />
          <br />
          <br />
          <div className="store">
            <div className="item">
              <div className="chest chest1">
                <div className="chestAlpha"></div>
                <div
                  className="buttonPlanet"
                  onClick={() => buyNft(1, "SPACESHIP")}
                >
                  ALPHA
                  <br />
                  {PRICES.SPACESHIP[1] / 10 ** 6} USDT
                </div>
              </div>
            </div>

            <div className="item">
              <div className="chest chest2">
                <div className="chestBeta"></div>
                <div
                  className="buttonPlanet"
                  onClick={() => buyNft(2, "SPACESHIP")}
                >
                  BETA
                  <br />
                  {PRICES.SPACESHIP[2] / 10 ** 6} USDT
                </div>
              </div>
            </div>

            <div className="item ch3">
              <div className="chest chest3">
                <div className="chestDelta"></div>
                <div
                  className="buttonPlanet"
                  onClick={() => {
                    // openModalMint();
                    buyNft(3, "SPACESHIP");
                  }}
                >
                  DELTA
                  <br />
                  {PRICES.SPACESHIP[3] / 10 ** 6} USDT
                </div>
              </div>
            </div>
          </div>
          <div className="br"></div>
          <div className="br"></div>
          <div className="store planetsFlex">
            <div className="item">
              <div className="planet planet-1">
                <div
                  className="buttonPlanet"
                  onClick={() => buyNft(1, "PLANET")}
                >
                  PLANET Y
                  <br />
                  {PRICES.PLANET[1] / 10 ** 6} USDT
                </div>
              </div>
            </div>
            <div className="item">
              <div className="planet planet-2">
                <div
                  className="buttonPlanet"
                  onClick={() => buyNft(2, "PLANET")}
                >
                  PLANET Y
                  <br />
                  {PRICES.PLANET[2] / 10 ** 6} USDT
                </div>
              </div>
            </div>
            <div className="item">
              <div className="planet planet-3">
                <div
                  className="buttonPlanet"
                  onClick={() => buyNft(3, "PLANET")}
                >
                  PLANET Z
                  <br />
                  {PRICES.PLANET[3] / 10 ** 6} USDT
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>

      {/* {isMinted ? <ModalMinted /> : ""} */}
      <ModalProfile />
    </div>
  );
};
