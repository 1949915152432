/* eslint-disable jsx-a11y/alt-text */
import { useCallback, useState } from "react";
import axios from "axios";
import { configureChains, createConfig, mainnet } from "wagmi";
import {
  TOKEN_NAME,
  loader,
  API_URL,
  API_VERSION,
  getParameterByName,
  toast,
  setTokenLogin,
  signupEmail,
  WALLET_LOGIN_MESSAGE,
  WALLET_REGISTER_MESSAGE,
} from "../utils";
import { publicProvider } from "wagmi/providers/public";

import { ethers } from "ethers";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet],
  [publicProvider()]
);
const config = createConfig({
  publicClient,
  webSocketPublicClient,
});
const LoginPage = () => {
  const [connectedWallet, setConnectedWallet] = useState<string | null>(null);

  const connectNow = useCallback(async () => {
    loader(true);

    try {
      const provider = new ethers.providers.Web3Provider(
        (window as any).ethereum
      );

      await provider.send("eth_requestAccounts", []);

      const signer = provider.getSigner();
      const wallet = await signer.getAddress();
      setConnectedWallet(wallet);

      const url = API_URL + API_VERSION + "/auth/ping";
      const data = {
        address: wallet,
      };

      axios
        .post(url, data)
        .then(async (response: any) => {
          console.log(response);
          console.log(response.data.status);
          if (response.data.status) {
            console.log("ajaxRun login");
            ajaxRun("login");
          } else {
            console.log("ajaxRun register");
            ajaxRun("register");
          }
          loader(false);
        })
        .catch((error) => {
          console.error("Error in ping:", error);
          loader(false);
        });

      const ajaxRun = (type: string) => {
        const url = API_URL + API_VERSION + "/auth/code/wallet";
        const data = {
          address: wallet,
          type,
        };

        axios
          .post(url, data)
          .then(async (response: any) => {
            const nonce = response.data.nonce;
            const ip = response.data.ip;

            let message = "";

            if (type === "register") {
              message = WALLET_REGISTER_MESSAGE({
                address: wallet,
                code: nonce,
                ip,
              });
              const signature = await signer.signMessage(
                ethers.utils.toUtf8Bytes(message)
              );
              const referral = getParameterByName("referred");
              processWallet(
                {
                  name: wallet,
                  address: wallet,
                  signature,
                  referral,
                },
                "register"
              );
            } else {
              message = WALLET_LOGIN_MESSAGE({
                address: wallet,
                code: nonce,
                ip,
              });
              const signature = await signer.signMessage(
                ethers.utils.toUtf8Bytes(message)
              );
              processWallet(
                {
                  address: wallet,
                  signature,
                },
                "login"
              );
            }
          })
          .catch((error) => {
            console.log("error", error);
            toast("Network error when authenticating with wallet");
          });
      };
    } catch (error: any) {
      loader(false);
      toast(error.message);
      console.error("Error connecting to wallet:", error);
    }
  }, []);

  let token = localStorage.getItem(TOKEN_NAME);
  if (token !== null) {
    token = JSON.parse(atob(token));
  }
  const planetClick = () => {
    alert("Under Construction 🚀🪐");
  };

  const processWallet = (data: any, type: string) => {
    console.log("type", type);
    const url = API_URL + API_VERSION + `/auth/${type}/wallet`;

    axios
      .post(url, data)
      .then((response: any) => {
        setTokenLogin(response);
        toast("Connected Wallet");
        setTimeout(() => {
          (window as any).location.href = "https://mint.spacewars.center/";
        }, 1000);
      })
      .catch((error) => {
        loader(false);
        console.error("Error en la solicitud:", error);
        toast(error.response.data.error.message);
      });
  };

  return (
    <div>
      <br />
      <br />
      <br />
      <img src="assets/logo.png" id="logo" />
      <div className="centerDiv txt-center">
        <h1>Access the Universe</h1>
        <h3>
          Embark on your space odyssey! Signup to unlock the gateway to infinite
          adventures. <br />
          Join the cosmic community and let the interstellar journey begin with
          just one click.
        </h3>
        <br />
        <br />
        <br />
        <div>
          <button className="btn btnAuth" onClick={signupEmail}>
            <strong>EMAIL</strong>
          </button>

          <button className="btn btnAuth" onClick={connectNow}>
            <strong>WALLET</strong>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
