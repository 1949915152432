export const TOKEN_NAME = "token_space_wars";
export const API_URL_DEV = "https://api-spacewars.devel.place";
export const API_URL_PRO = "https://api.spacewars.center";
export const API_URL = API_URL_PRO;
export const API_VERSION = "/v1";
export const CONTRACT = "0x89E0Dcb4C61086F517C439Ff7999285936CFd220";
export const CONTRACT_USDT = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
export const PLANET = "0x510bB068BbEC79ed3Bd7a332fB4E62253f69640c";
export const SPACESHIP = "0x64593Fd1C6b9d9c136bC1a8FB05Ffb642501f124";

export const ABI = [
  {
    inputs: [
      {
        internalType: "enum NFTSale.TypeP",
        name: "type_",
        type: "uint8",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "buyPlanet",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum NFTSale.TypeS",
        name: "type_",
        type: "uint8",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "buySpaceship",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

interface iPrices {
  SPACESHIP: iIdNFT;
  PLANET: iIdNFT;
}
interface iIdNFT {
  1: number;
  2: number;
  3: number;
}

export const PRICES: iPrices = {
  SPACESHIP: {
    1: 26000000,
    2: 71000000,
    3: 242000000,
  },
  PLANET: {
    1: 197000000,
    2: 998000000,
    3: 2960000000,
  },
};

export const loader = (isShow: boolean) => {
  if (isShow) {
    $(".bgLoader").css("display", "flex");
  } else {
    $(".bgLoader").hide();
  }
};

export const signupEmail = () => {
  $(".modal").hide();
  $(".modalSignupEmail").css("display", "flex");
};

export const profile = () => {
  $(".modal").hide();
  $(".modalProfile").css("display", "flex");
};

export const toast = (message: string) => {
  $("#toastTag").html(message).css("bottom", "20px");
  setTimeout(() => {
    $("#toastTag").css("bottom", "-60px");
  }, 2500);
};

export const getParameterByName = (name: string) => {
  const url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const setTokenLogin = (response: any) => {
  const token = btoa(JSON.stringify(response.data));
  localStorage.setItem(TOKEN_NAME, token);
};

export const setContract = () => {
  //

  return;
};

export const getToken = () => {
  let token = localStorage.getItem(TOKEN_NAME);
  if (token !== null) {
    token = JSON.parse(atob(token));
    console.log("token", token);
  }
  return token;
};

export const copyToClipboard = (str: string) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected =
    (document.getSelection() as any).rangeCount > 0
      ? (document.getSelection() as any).getRangeAt(0)
      : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    (document.getSelection() as any).removeAllRanges();
    (document.getSelection() as any).addRange(selected);
  }
};

const w = window;
export const logout = () => {
  localStorage.removeItem(TOKEN_NAME);
  (window as any).location.href = "https://mint.spacewars.center/";
  // if (w.confirm("¿Logout...?")) {
  // }
};

type iAuthMessage = {
  address: string;
  code: string;
  ip: string;
};

type iLinkMessage = {
  address: string;
  code: string;
  ip: string;
  email: string;
};

export const WALLET_LOGIN_MESSAGE = ({ address, code, ip }: iAuthMessage) =>
  `Welcome Back!

Your galaxy awaits your command. Sign this message to validate your identity in the game.

Address: ${address}
Nonce: ${code}
IP: ${ip}

Thank you for ensuring the safety of our cosmic community!`;

export const WALLET_REGISTER_MESSAGE = ({ address, code, ip }: iAuthMessage) =>
  `Welcome to Space Wars!

Dear Player, get ready to embark on an interstellar journey of epic battles and galactic conquests. Sign this message to validate your identity in the game.

Address: ${address}
Nonce: ${code}
IP: ${ip}

This unique signature guarantees your exclusive access and interactions within the Space Wars universe. Let the adventure begin!`;

export const WALLET_LINK_MESSAGE = ({
  address,
  code,
  ip,
  email,
}: iLinkMessage) =>
  `Greetings, Space Commander!

You're one step away from adding an email address to your Space Wars account. Sign this message to validate your identity in the game.

Address: ${address}
Email: ${email}
Nonce: ${code}
IP: ${ip}

Thank you for updating your account and contributing to the security of our interstellar community!`;
