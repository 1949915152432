/* eslint-disable jsx-a11y/alt-text */

import { useEffect, useState } from "react";
import { TConductorInstance } from "react-canvas-confetti/dist/types";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import {
  copyToClipboard,
  getToken,
  logout,
  signupEmail,
  toast,
} from "../utils";

export const CountdownPage = () => {
  const token = getToken() as any;
  let refLink = "";
  if (token !== null) {
    refLink =
      "https://mint.spacewars.center/?referred=" + token.user.referralCode;
  }

  const init = () => {
    const countdownEls = document.querySelectorAll(".countdown");
    countdownEls.forEach((countdownEl) => createCountdown(countdownEl));
    console.log("init", countdownEls);

    function createCountdown(countdownEl: any) {
      const target = new Date(
        new Date(countdownEl.dataset.targetDate).toLocaleString("en")
      );
      const parts = {
        days: { text: ["days", "day"], dots: 30 },
        hours: { text: ["hours", "hour"], dots: 24 },
        minutes: { text: ["minutes", "minute"], dots: 60 },
        seconds: { text: ["seconds", "second"], dots: 60 },
      };

      Object.entries(parts).forEach(([key, value]: any[]) => {
        const partEl = document.createElement("div");
        partEl.classList.add("part", key);
        partEl.style.setProperty("--dots", value.dots);
        value.element = partEl;

        const remainingEl = document.createElement("div");
        remainingEl.classList.add("remaining");
        remainingEl.innerHTML = `
                <span class="number"></span>
                <span class="text"></span>
            `;
        partEl.append(remainingEl);
        for (let i = 0; i < value.dots; i++) {
          const dotContainerEl = document.createElement("div");
          dotContainerEl.style.setProperty("--dot-idx", i.toString());
          dotContainerEl.classList.add("dot-container");
          const dotEl = document.createElement("div");
          dotEl.classList.add("dot");
          dotContainerEl.append(dotEl);
          partEl.append(dotContainerEl);
        }
        countdownEl.append(partEl);
      });
      getRemainingTime(target, parts);
    }

    function getRemainingTime(target: any, parts: any, first = true) {
      const now = new Date() as any;
      if (first) console.log({ target, now });
      const remaining = {};
      let seconds = Math.floor((target - now) / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
      Object.entries({ days, hours, minutes, seconds }).forEach(
        ([key, value]) => {
          const remaining = parts[key].element.querySelector(".number");
          const text = parts[key].element.querySelector(".text");
          remaining.innerText = value;
          text.innerText = parts[key].text[Number(value == 1)];
          const dots = parts[key].element.querySelectorAll(".dot");
          dots.forEach((dot: any, idx: any) => {
            dot.dataset.active = idx <= value;
            dot.dataset.lastactive = idx == value;
          });
        }
      );
      if (now <= target) {
        window.requestAnimationFrame(() => {
          getRemainingTime(target, parts, false);
        });
      }
    }
  };

  const [conductor, setConductor] = useState<TConductorInstance>();
  const onOnce = () => {
    console.log("onOnce 1");
    conductor?.shoot();
    console.log("onOnce 2");
  };
  const onRun = () => {
    conductor?.run({ speed: 3 });
  };
  const onPause = () => {
    conductor?.pause();
  };
  const onStop = () => {
    conductor?.stop();
  };

  const onInit = ({ conductor }: { conductor: TConductorInstance }) => {
    setConductor(conductor);
  };

  useEffect(() => {
    const targetDate = new Date(1707422400000);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    }).format(targetDate);

    const countdownElement = document.querySelector(".countdown");
    if (countdownElement) {
      countdownElement.setAttribute("data-target-date", formattedDate);
      init();
    }
  }, []);

  return (
    <div>
      <br />
      <br />
      <img src="assets/logo.png" id="logo" />

      <div className="centerDiv txt-center">
        <h1 className="upperCase">Coming Soon</h1>
        <h3 className="boxed-txt">
          Discover the universe's wonders in our NFT presale featuring stellar
          spaceships, celestial bodies, and intergalactic art. Don't miss the
          countdown—secure your exclusive access now! 🚀✨
        </h3>
        {token ? (
          <div>
            <div>
              <div className="txt m0 txt-center">
                <strong className="strongAlign">
                  <div className="infoProfile">
                    User: &nbsp;{token.user.email ?? token.user.name}
                  </div>
                  <button className="btn" onClick={logout}>
                    <strong>LOGOUT</strong>
                  </button>
                </strong>
              </div>
            </div>
            <br />

            <div className="f22">
              Invite now: <br />
              <span className="usAuto">{refLink}</span>
              <button
                className="btn btnAuth"
                onClick={() => {
                  toast("Copied Link ✅");
                  copyToClipboard(refLink);
                }}
              >
                <strong>COPY LINK</strong>
              </button>
            </div>
          </div>
        ) : (
          <div>
            Register with: <br /> <br />
            <button className="btn btnAuth" onClick={signupEmail}>
              <strong>EMAIL</strong>
            </button>
          </div>
        )}

        <br />
        <br />
        <div className="miniGalaxy">
          <div
            className="countdown"
            data-target-date="2024-02-09 13:00:00"
          ></div>

          {/* <div id="shipDirection">
            <div id="spaceShip" className="ship" planet-slot="0">
              <img src="assets/flame.gif" className="flame_ship" />
              <img src="assets/Light_Spaceship_1.png" className="ship_1" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
