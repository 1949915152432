/* eslint-disable jsx-a11y/alt-text */
import $ from "jquery";
import {
  API_URL,
  API_VERSION,
  TOKEN_NAME,
  getParameterByName,
  loader,
  toast,
} from "../utils";
import axios from "axios";

export const openLogin = () => {
  $(".modal").hide();
  $(".modalLoginEmail").css("display", "flex");
};

export const openModalMint = () => {
  $(".modal").hide();
  $(".modalMint").css("display", "flex");
};

export const SignupFormEmail = () => {
  const referralParam = getParameterByName("referred");

  const getCodeEmail = () => {
    const emailInputValue = $("#emailInput").val() as string;

    if (emailInputValue === "") {
      return toast("Enter your email");
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(emailInputValue)) {
        return toast("Enter a valid email");
      }
    }

    loader(true);

    const url = API_URL + API_VERSION + "/auth/code/email";
    const email = $("#emailInput").val();
    const data = {
      email,
      type: "register",
    };

    axios
      .post(url, data)
      .then((response) => {
        loader(false);
        toast("Code sent successfully");
        console.log("Respuesta exitosa:", response.data);
      })
      .catch((error) => {
        loader(false);
        toast("Network error");
        console.error("Error en la solicitud:", error);
      });
  };

  const formLogin = (event: any) => {
    event.preventDefault();
    if ($("#passwordInput").val() !== $("#passwordInputAux").val()) {
      return toast("Passwords do not match");
    }
    loader(true);

    const url = API_URL + API_VERSION + "/auth/register/email";
    const ref = referralParam ? referralParam : $("#referralInput").val();
    const data = {
      name: $("#nameInput").val(),
      email: $("#emailInput").val(),
      password: $("#passwordInput").val(),
      code: $("#codeInput").val(),
      referral: ref,
    };

    axios
      .post(url, data)
      .then((response) => {
        toast("Successfully registered");
        const token = btoa(JSON.stringify(response.data));
        localStorage.setItem(TOKEN_NAME, token);
        console.log(response);
        setTimeout(() => {
          (window as any).location.href = "https://mint.spacewars.center/";
        }, 1000);
      })
      .catch((error) => {
        loader(false);
        console.error("Error en la solicitud:", error);
        toast(error.response.data.error.message);
      });
  };

  return (
    <div className="modal modalSignupEmail closeModal">
      <div className="content">
        <div className="title">Signup</div>
        <form onSubmit={formLogin} className="formLogin">
          <label>
            &nbsp;&nbsp;&nbsp;&nbsp;Name:
            <input
              type="text"
              id="nameInput"
              placeholder="Enter your Name"
              required
            />
          </label>

          <label>
            &nbsp;&nbsp;&nbsp;&nbsp;Email:
            <input
              type="email"
              id="emailInput"
              placeholder="Enter your Email"
              required
            />
          </label>

          <label>
            &nbsp;&nbsp;&nbsp;&nbsp;Verification Code:
            <input
              type="text"
              id="codeInput"
              placeholder="Enter your Code"
              required
            />
          </label>

          <label>
            <div onClick={getCodeEmail} className="btn vcBtn">
              Get Code in Email
            </div>
          </label>

          <label>
            &nbsp;&nbsp;&nbsp;&nbsp;Password:
            <input
              type="password"
              id="passwordInput"
              placeholder="Enter your Password"
              required
            />
          </label>

          <label>
            &nbsp;&nbsp;&nbsp;&nbsp;Confirm Password:
            <input
              type="password"
              id="passwordInputAux"
              placeholder="Enter your Password"
              required
            />
          </label>

          {referralParam ? (
            <p className="w100 txt-center">Referral: {referralParam}</p>
          ) : (
            <label>
              &nbsp;&nbsp;&nbsp;&nbsp;Referral Id:
              <input
                type="text"
                id="referralInput"
                placeholder="Enter your Referral id"
              />
              <p></p>
            </label>
          )}

          <label className="legal">
            <input type="checkbox" required />I have read and accept the terms
            and conditions of use.
          </label>

          <div className="btnSignup">
            <div
              className="btn btnCancel"
              onClick={() => {
                $(".closeModal").hide();
              }}
            >
              Cancel
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button className="btn">SignUp</button>
          </div>
        </form>

        <div className="endSection">
          <p>Do you already have an account?</p>
          <button className="btn" onClick={openLogin}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
};
